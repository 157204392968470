import type { FC } from 'react';
import React, { Fragment, useCallback } from 'react';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl-next';

import QuotationMarkIcon from '@atlaskit/icon/core/quotation-mark';
import { SpotlightManager, SpotlightTarget } from '@atlaskit/onboarding';

import {
	ExpandableMenuItem,
	ExpandableMenuItemContent,
	ExpandableMenuItemTrigger,
} from '@atlassian/navigation-system/side-nav/expandable-menu-item';

import { SpacePersistentCollapsibleSectionLoader } from '@confluence/collapsible-section/entry-points/SpacePersistentCollapsibleSectionLoader';
import { useSpacePersistentExpansion } from '@confluence/collapsible-section/entry-points/useSpacePersistentExpansion';
import { CreateBlogButton } from '@confluence/contextual-create';
import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import { useIsNav4Enabled } from '@confluence/nav4-enabled';
import { fg } from '@confluence/feature-gating';
import { useOnboardingState } from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import { ONBOARDING_BLOG_TREE } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';
import { ExperienceStart, BLOG_TREE_SPOTLIGHT_EXPERIENCE } from '@confluence/experience-tracker';
import { useContentType } from '@confluence/page-context';

import { BlogTreeRootComponent } from './BlogTreeRootComponent';
import { BlogTreeSpotlight } from './BlogTreeSpotlight';

type BlogTreeProps = {
	spaceKey: string;
	pageTreeFinishedLoading: boolean;
	isNewUser: boolean;
	isLicensed: boolean;
};

const i18n = defineMessages({
	blogsTitle: {
		id: 'blog-tree.section.header.title',
		defaultMessage: 'Blogs',
		description: 'Title of Blog tree section in space side navigation',
	},
	blogsTooltip: {
		id: 'blog-tree.section.header.tooltip',
		defaultMessage: 'View all blogs in this space',
		description: 'Tooltip message on hover of Blogs section header',
	},
});

export const BlogTree: FC<BlogTreeProps> = ({
	spaceKey,
	pageTreeFinishedLoading,
	isNewUser,
	isLicensed,
}) => {
	const [pageContextContentType] = useContentType();
	const { formatMessage } = useIntl();
	const isNav4Enabled = useIsNav4Enabled();
	const { setOnboardingState } = useOnboardingState();
	const expandedByDefaultBecauseViewingBlog = pageContextContentType === 'blogpost';
	const { isExpanded, onExpansionToggle } = useSpacePersistentExpansion(
		'blogTree',
		!expandedByDefaultBecauseViewingBlog,
	);

	const dismissBlogTreeSpotlight = useCallback(() => {
		void setOnboardingState({
			key: ONBOARDING_BLOG_TREE.BLOG_TREE_HEADER_SPOTLIGHT_DISMISSED_KEY,
			value: 'true',
		});
	}, [setOnboardingState]);

	return (
		<ErrorBoundary attribution={Attribution.DISCO}>
			<SpotlightManager blanketIsTinted={false} onBlanketClicked={dismissBlogTreeSpotlight}>
				{isNav4Enabled ? (
					<ExpandableMenuItem isExpanded={isExpanded} onExpansionToggle={onExpansionToggle}>
						<SpotlightTarget name="sideNavCollapsibleSectionSpotlight">
							<ExpandableMenuItemTrigger
								elemBefore={<QuotationMarkIcon label="" color="currentColor" />}
								actionsOnHover={<CreateBlogButton spaceKey={spaceKey} />}
							>
								<FormattedMessage {...i18n.blogsTitle} />
							</ExpandableMenuItemTrigger>
						</SpotlightTarget>
						<ExpandableMenuItemContent>
							<BlogTreeRootComponent
								spaceKey={spaceKey}
								pageTreeFinishedLoading={pageTreeFinishedLoading}
								isNav4Enabled={isNav4Enabled}
							/>
						</ExpandableMenuItemContent>
					</ExpandableMenuItem>
				) : (
					<SpacePersistentCollapsibleSectionLoader
						renderAfterIconButton={() => <CreateBlogButton spaceKey={spaceKey} />}
						headerTitle={formatMessage(i18n.blogsTitle)}
						sectionName="blogTree"
						tooltipContent={formatMessage(i18n.blogsTooltip)}
						collapsedByDefault={!expandedByDefaultBecauseViewingBlog}
					>
						<BlogTreeRootComponent
							spaceKey={spaceKey}
							pageTreeFinishedLoading={pageTreeFinishedLoading}
						/>
					</SpacePersistentCollapsibleSectionLoader>
				)}
				{pageTreeFinishedLoading &&
					!isNewUser &&
					isLicensed &&
					fg('blog_tree_under_content_tree_spotlight') && (
						<Fragment>
							<ExperienceStart name={BLOG_TREE_SPOTLIGHT_EXPERIENCE} />
							<BlogTreeSpotlight dismissBlogTreeSpotlight={dismissBlogTreeSpotlight} />
						</Fragment>
					)}
			</SpotlightManager>
		</ErrorBoundary>
	);
};
