import { createContext, type Dispatch, type SetStateAction } from 'react';

import __noop from '@atlaskit/ds-lib/noop';

/**
 * Context for the visibility of the side nav on desktop screens.
 */
export const SideNavDesktopVisibility = createContext<boolean | null>(null);
/**
 * Context for the visibility of the side nav on mobile screens.
 */
export const SideNavMobileVisibility = createContext<boolean>(false);

/**
 * Sets the visibility of the side nav on desktop screens.
 * Internally exporting for use in tests.
 */
export const SetSideNavDesktopVisibility =
	createContext<Dispatch<SetStateAction<boolean | null>>>(__noop);
/**
 * Sets the visibility of the side nav for mobile screens
 * Internally exporting for use in tests.
 */
export const SetSideNavMobileVisibility = createContext<Dispatch<SetStateAction<boolean>>>(__noop);
