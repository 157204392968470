import { useCallback, useContext } from 'react';

import { SetSideNavDesktopVisibility, SetSideNavMobileVisibility } from './visibility-context';

type ExpandSideNav = () => void;

/**
 * __useExpandSideNav__
 *
 * Returns a function that will expand the side nav when called.
 *
 * It will switch the appropriate internal desktop or mobile side nav visibility state based on the current screen size.
 *
 * If you need a function to toggle the side nav, use `useToggleSideNav` instead.
 */
export function useExpandSideNav(): ExpandSideNav {
	const setVisibleOnDesktop = useContext(SetSideNavDesktopVisibility);
	const setVisibleOnMobile = useContext(SetSideNavMobileVisibility);

	const expandSideNav = useCallback(() => {
		const { matches } = window.matchMedia('(min-width: 64rem)');
		if (matches) {
			setVisibleOnDesktop(true);
		} else {
			setVisibleOnMobile(true);
		}
	}, [setVisibleOnDesktop, setVisibleOnMobile]);

	return expandSideNav;
}
