import React, { useState, useEffect, Fragment, useContext } from 'react';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl-next';

import { usePageLayoutResize } from '@atlaskit/page-layout';
import { SpotlightTransition } from '@atlaskit/onboarding';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { Text } from '@atlaskit/primitives';

import { useExpandSideNav } from '@atlassian/navigation-system/layout/side-nav';

import {
	useGetOnboardingState,
	deserializeState,
} from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import { ONBOARDING_BLOG_TREE } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';
import { ErrorDisplay } from '@confluence/error-boundary';
import {
	BLOG_TREE_SPOTLIGHT_EXPERIENCE,
	ExperienceSuccess,
	ExperienceFailure,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { useChoreographerAssets } from '@confluence/choreographer-services';
import { useIsNav4Enabled } from '@confluence/nav4-enabled';

const i18n = defineMessages({
	blogTreeSpotlightHeader: {
		id: 'blog-tree.spotlight.title',
		defaultMessage: 'Blogs have moved',
		description: 'Title of onboarding spotlight card that spotlights blog tree header',
	},
	blogTreeSpotlightDescription: {
		id: 'blog-tree.spotlight.description',
		defaultMessage: 'We’ve put them closer to the rest of your work, right below {boldedContent}.',
		description: 'Description of onboarding spotlight card that spotlights blog tree header',
	},
	blogTreeSpotlightGotIt: {
		id: 'blog-tree.spotlight.got-it.button',
		defaultMessage: 'Got it',
		description: 'Text for button on spotlight card to dismiss the spotlight',
	},
	blogTreeSpotlightDescriptionContent: {
		id: 'blog-tree.spotlight.description.content.text',
		defaultMessage: 'Content',
		description: 'Text to be bolded for spotlight descriptiohn',
	},
});

export const BlogTreeSpotlight = ({ dismissBlogTreeSpotlight }) => {
	const { Spotlight } = useChoreographerAssets();
	const experienceTracker = useContext(ExperienceTrackerContext);
	const expandSideNav = useExpandSideNav();
	const isNav4Enabled = useIsNav4Enabled();

	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [showSpotlight, setShowSpotlight] = useState(false);

	const {
		data: onboardingStateData,
		error: onboardingStateError,
		loading: onboardingStateLoading,
	} = useGetOnboardingState(Object.values(ONBOARDING_BLOG_TREE));

	const { isLeftSidebarCollapsed, expandLeftSidebar } = usePageLayoutResize();
	const { blogTreeHeaderChangeboardingSpotlightDismissed } = deserializeState(onboardingStateData);
	const handleGotItClick = () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'blogTreeSpotlight',
				actionSubjectId: 'gotItButton',
				source: 'sideNavigation',
			},
		}).fire();
		dismissBlogTreeSpotlight();
	};

	useEffect(() => {
		if (
			!blogTreeHeaderChangeboardingSpotlightDismissed &&
			!onboardingStateError &&
			!onboardingStateLoading
		) {
			if (isLeftSidebarCollapsed) {
				expandLeftSidebar();
			}
			if (isNav4Enabled) {
				expandSideNav();
			}
			setShowSpotlight(true);
			createAnalyticsEvent({
				type: 'sendScreenEvent',
				data: {
					name: 'blogTreeSpotlight',
					source: 'sideNavigation',
				},
			}).fire();
			window.addEventListener('beforeunload', dismissBlogTreeSpotlight);
		} else {
			// spotlight is expected not to show
			experienceTracker.abort({
				name: BLOG_TREE_SPOTLIGHT_EXPERIENCE,
				reason: 'spotlight has already been dismissed before',
			});

			setShowSpotlight(false);
		}

		return () => {
			window.removeEventListener('beforeunload', dismissBlogTreeSpotlight);
		};
	}, [
		blogTreeHeaderChangeboardingSpotlightDismissed,
		isLeftSidebarCollapsed,
		onboardingStateError,
		onboardingStateLoading,
		expandLeftSidebar,
		createAnalyticsEvent,
		dismissBlogTreeSpotlight,
		experienceTracker,
		isNav4Enabled,
		expandSideNav,
	]);

	if (onboardingStateError) {
		return (
			<Fragment>
				<ErrorDisplay error={onboardingStateError} />
				<ExperienceFailure name={BLOG_TREE_SPOTLIGHT_EXPERIENCE} error={onboardingStateError} />
			</Fragment>
		);
	}

	return showSpotlight ? (
		<SpotlightTransition>
			<Fragment>
				<Spotlight
					actions={[
						{
							onClick: handleGotItClick,
							text: formatMessage(i18n.blogTreeSpotlightGotIt),
						},
					]}
					heading={formatMessage(i18n.blogTreeSpotlightHeader)}
					target="sideNavCollapsibleSectionSpotlight"
					key="sideNavCollapsibleSectionSpotlight"
					targetRadius={3}
					dialogPlacement="right middle"
					messageType="engagement"
					messageId="blog-tree-spotlight"
				>
					<FormattedMessage
						{...i18n.blogTreeSpotlightDescription}
						values={{
							boldedContent: (
								<Text weight="bold">
									<FormattedMessage {...i18n.blogTreeSpotlightDescriptionContent} />
								</Text>
							),
						}}
					/>
				</Spotlight>
				<ExperienceSuccess name={BLOG_TREE_SPOTLIGHT_EXPERIENCE} />
			</Fragment>
		</SpotlightTransition>
	) : null;
};
